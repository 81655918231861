import React from "react";
import {
  Card,
  CardHeader,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";

const AlpakaCard = (props) => {


  const { name, description, image_url, teaser, sponsorship, pate, pate_name, pate_25, pate_25_name ,pate_custom_text } = props.image;
  return (

    <Card className="m-2">
      <CardImg src={image_url} alt={` of ${name}`} />
      <CardHeader>
        <h3>{name}</h3>
      </CardHeader>
      <CardBody>
        <CardTitle tag="h5">{teaser}</CardTitle>
        <CardText
          dangerouslySetInnerHTML={{ __html: description }}
        ></CardText>
        {sponsorship &&
          <Button
          className="mr-1"
            color="link"
            href="/patenschaft"
          >
            <i className="fa fa-heart mr-1" />
            Patenschaft übernehmen
          </Button>
        }
        {pate &&
          <CardText>Die Patenschaft für das Jahr 2024 ist {pate_name && `an ${pate_name}` } vergeben.
          </CardText>
        }
         {pate_25 &&
          <CardText>Die Patenschaft für das Jahr 2025 ist {pate_25_name && `an ${pate_25_name}` } vergeben.
          </CardText>
        }
        {pate_custom_text && <CardText>{pate_custom_text}</CardText>}
      </CardBody>
    </Card>
  );
};
export default AlpakaCard;
